import { useRef } from "react";

import { styled } from "stitches.config";

const TooltipContainerStyled = styled("div", {
  position: "relative",
  display: "inline-block",
});

const TooltipTriggerStyled = styled("div", {
  cursor: "pointer",
  display: "inline",
});

const tooltipPositionLeft = -1.5;
const tooltipPositionLeftMobile = -0.75;

const TooltipContentWrapperStyled = styled("div", {
  paddingTop: "1rem",
  position: "absolute",
  top: "100%",
  left: `${tooltipPositionLeftMobile}rem`,
  zIndex: 10,
  display: "none",

  "@md": {
    left: `${tooltipPositionLeft}rem`,
  },
});

const indicatorWidth = 1.5;
const indicatorHeight = 1.5;
const indicatorBorderWidth = 2;
const indicatorDiagonal = indicatorWidth * Math.sqrt(2);
const indicatorPositionLeft = 0.9;
const indicatorPositionLeftMobile = 0.25;

const TooltipDescriptionStyled = styled("div", {
  display: "inline",
});

const TooltipContentStyled = styled("div", {
  position: "relative",
  padding: "0.75rem 1rem",
  width: "360px",
  border: "2px solid #e3e6e8",
  borderRadius: "0.5rem",
  background: "#fff",
  fontSize: "0.8125rem",
  fontWeight: "500",
  lineHeight: "1.4",
  color: "#566a77",
  boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",

  "&:before": {
    content: "",
    width: `${indicatorWidth}rem`,
    height: `${indicatorHeight}rem`,
    position: "absolute",
    zIndex: "-1",
    top: `-${indicatorHeight / 2}rem`,
    left: `calc(((${indicatorDiagonal}rem - ${indicatorWidth}rem) / 2) - ${indicatorBorderWidth}px + ${indicatorPositionLeftMobile}rem)`,
    border: `${indicatorBorderWidth}px solid #e3e6e8`,
    borderRadius: "0.25rem 0 0 0 ",

    backgroundColor: "#fff",
    transform: "rotate(45deg)",
    boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
  },

  "&:after": {
    content: "",
    width: `calc(${indicatorWidth}rem - ${indicatorBorderWidth * 2}px)`,
    height: `calc(${indicatorHeight}rem - ${indicatorBorderWidth * 2}px)`,
    position: "absolute",
    zIndex: "1",
    top: `calc(-${indicatorHeight / 2}rem + ${indicatorBorderWidth}px)`,
    left: `calc(((${indicatorDiagonal}rem - ${indicatorWidth}rem) / 2) - ${indicatorBorderWidth}px + ${indicatorBorderWidth}px + ${indicatorPositionLeftMobile}rem)`,

    borderRadius: "0.25rem 0 0 0 ",

    backgroundColor: "#fff",
    transform: "rotate(45deg)",
  },

  "@md": {
    padding: "1rem 1.5rem",
    width: "440px",

    "&:before": {
      left: `calc(((${indicatorDiagonal}rem - ${indicatorWidth}rem) / 2) - ${indicatorBorderWidth}px + ${indicatorPositionLeft}rem)`,
    },

    "&:after": {
      left: `calc(((${indicatorDiagonal}rem - ${indicatorWidth}rem) / 2) - ${indicatorBorderWidth}px + ${indicatorBorderWidth}px + ${indicatorPositionLeft}rem)`,
    },
  },
});

export const Tooltip = ({ className, trigger, description, children }) => {
  const tooltipContainerRef = useRef();
  const tooltipContentWrapperRef = useRef();

  const showTooltip = () => {
    tooltipContentWrapperRef.current.style.display = "block";
  };

  const hideTooltip = () => {
    tooltipContentWrapperRef.current.style.display = "none";
  };

  const toggleTooltip = () => {
    tooltipContentWrapperRef.current.style.display =
      tooltipContentWrapperRef.current.style.display === "none" ? "block" : "none";
  };

  return (
    <TooltipContainerStyled className={className + " rce"} onClick={toggleTooltip}>
      <TooltipTriggerStyled ref={tooltipContainerRef} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {trigger}
      </TooltipTriggerStyled>
      <TooltipDescriptionStyled>{description}</TooltipDescriptionStyled>
      <TooltipContentWrapperStyled ref={tooltipContentWrapperRef}>
        <TooltipContentStyled dangerouslySetInnerHTML={{ __html: children }} className="rce" />
      </TooltipContentWrapperStyled>
    </TooltipContainerStyled>
  );
};
